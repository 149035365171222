<template>
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center align-items-center min-vh-100">
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-9">
                  <div class="card px-lg-5 px-0 py-3">
                    <div class="card-body pb-0">
                      <div class="text-center">
                        <img
                          src="images/gardning-logo.svg"
                          width="180"
                          height="37"
                          class="img-fluid mb-4 mx-auto"
                        />
                        <p class="font-medium font-18">Sign In</p>
                      </div>

                      <form class="form-style floating-form">
                        <Form @submit="login">
                          <div class="floating-form-group with-icon">
                            <Field
                              type="text"
                              id="phone"
                              name="phone"
                              v-model="details.phone_number"
                              rules="required:phone number|phone"
                              :validateOnInput="true"
                            />
                            <label for="phone">Phone</label>
                            <i class="bx bx-phone icon"></i>
                            <span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="phone" class="validation-msg" />
                          <div class="floating-form-group mb-3 with-icon">
                            <Field
                              :type="passwordFieldType"
                              id="password"
                              class="form-control border-end-0"
                              v-model="details.password"
                              name="Password"
                              rules="required:password"
                              :validateOnInput="true"
                            />
                            <label for="password">Password</label>
                            <i class="bx bx-lock icon"></i>
                            <span
                              class="input-group-text bg-white"
                              id="basic-addon2"
                              style="border-radius: 0px 8px 8px 0px"
                              ><a v-on:click="switchVisibility" class="psw-icon"
                                ><img :src="image" width="20" /></a
                            ></span>
                            <ErrorMessage name="Username" class="validation-msg" />
                          </div>
                          <div class="form-check pl-0 mb-3 overflow-auto">
                            <a class="text-dark float-right" style="color:#00BB59 !important;" href="/forgot-password"
                              >Forgot Password?</a
                            >
                          </div>
                          <button
                            id="login-button"
                            style="cursor: pointer"
                            class="btn btn-brand-1 btn-block"
                          >
                            Sign in
                          </button>
                        </Form>
                      </form>
                    </div>
                    <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Don't have an account?
                        <a href="signup" class="ml-2 text-primary font-medium" style="color:#00BB59 !important;">
                          Sign Up</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <div>
      <div>
        <main role="main">
          <div id="main-wrapper" class="login-wrap">
            <div class="container-fluid h-100">
              <div
                class="
                  row
                  justify-content-md-center
                  align-items-center
                  h-100
                  bg-white
                "
              >
                <div class="col-md-6 bg-white">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <div class="px-lg-5 px-0 py-3">
                        <div
                          class="
                            card-header
                            bg-white
                            rounded-10
                            text-center
                            border-0
                          "
                        >
                          <img
                            src="/images/beauty-logo.svg"
                            class="img-fluid mb-3"
                            width="200"
                            height="96"
                          />
                        </div>
                        <div class="card-body pt-1">
                          <Form @submit="login" class="form-style">
                            <div class="mb-3">
                              <div class="form-group">
                                <label for="email">Email</label
                                ><Field
                                  type="email"
                                  id="email"
                                  class="form-control"
                                  v-model="details.email"
                                  name="Username"
                                  rules="required:email|email"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage
                                name="Username"
                                class="validation-msg"
                              />
                            </div>
                            <div class="mb-3">
                              <div class="form-group">
                                <label for="password">Password</label>
                                <div class="input-group mb-3">
                                  <Field
                                    :type="passwordFieldType"
                                    id="password"
                                    class="form-control border-end-0"
                                    v-model="details.password"
                                    name="Password"
                                    rules="required:password"
                                    :validateOnInput="true"
                                  /><span
                                    class="input-group-text bg-white"
                                    id="basic-addon2"
                                    style="border-radius: 0px 8px 8px 0px"
                                    ><a
                                      v-on:click="switchVisibility"
                                      class="psw-icon"
                                      ><img :src="image" width="20" /></a
                                  ></span>
                                </div>
                                <ErrorMessage
                                  name="Password"
                                  class="validation-msg"
                                />
                              </div>
                              -->
    <!--</div>
                            <div class="pl-0 mb-3 text-end">
                              <a
                                style="cursor: pointer"
                                v-on:click="$router.push('/forgot-password')"
                                class="
                                  text-secondary
                                  font-14
                                  text-decoration-none
                                "
                                >Forgot Password?</a
                              >
                            </div>
                            <button
                              class="btn btn-black w-100 mb-3"
                              id="login-button"
                              style="cursor: pointer"
                            >
                              Log in
                            </button>
                          </Form>
                        </div>
                        <div
                          class="card-footer text-center bg-white border-0 pt-0"
                        >
                          <div class="links mb-4 font-14 text-secondary">
                            Don’t have an account?
                            <a
                              v-on:click="$router.push('/signup')"
                              class="ml-2 text-brand text-decoration-none"
                              style="cursor: pointer"
                            >
                              Sign Up</a
                            >
                          </div>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                              font-14
                              text-secondary text-nowrap
                              mb-4
                            "
                          >
                            <hr class="w-50 me-2" />
                            OR Login With
                            <hr class="w-50 ms-2" />
                          </div>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <a href=""
                              ><img
                                src="images/Google__G__Logo.svg"
                                alt="Google"
                                class="mx-3"
                                width="35"
                                height="35" /></a
                            ><a href=""
                              ><img
                                src="images/fb.svg"
                                alt="Facebook"
                                class="mx-3"
                                width="43"
                                height="43"
                            /></a>                  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 login-img-col"></div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "LoginPage",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      passwordFieldType: "password",
      image: "/images/show.png",
      details: {
        email: "",
        password: "",
        dial_code: "+91",
        country_code: "IN",
      },
    };
  },
  mounted() {
    localStorage.clear();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          // console.log("token",res.access_token)
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res) {
            // console.log("object",res);
            this.$store.commit("setUser", res.info);
          } else {
            localStorage.removeItem("access_token");
            return;
          }
          localStorage.setItem("userId", res.info.id);
          localStorage.setItem("supplier_id", res.info.company.supplier_id);
          // console.log("ussr",res.info.company.supplier_id);
          this.$router.push("/home");
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>

