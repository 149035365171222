<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row align-items-center">
            <div class="col-md-12 col-sm-12">
              <h2 class="page-title mb-0">{{ $t("label.Dashboard") }}</h2>
            </div>
          </div>
          <ul
            class="
              nav nav-pills
              mb-3
              tab-style-1
              bg-light
              justify-content-center
            "
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="product-tab"
                data-toggle="pill"
                href="#product"
                role="tab"
                aria-controls="product"
                aria-selected="true"
                >{{ $t("label.Products") }}</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="services-tab"
                data-toggle="pill"
                href="#services"
                role="tab"
                aria-controls="services"
                aria-selected="false"
                >{{ $t("label.Services") }}</a
              >
            </li>
          </ul>
          <div class="tab-content mt-4" id="pills-tabContent">
            <div
              class="tab-pane fade active show"
              id="product"
              role="tabpanel"
              aria-labelledby="product-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-4 col-12 mb-3">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/my-product')"
                  >
                    <img
                      src="images/all-products.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.All Products") }}</p>
                    <h2 class="text-brand mb-0">
                      {{
                        this.detail.my_product_counts
                          ? this.detail.my_product_counts
                          : "00"
                      }}
                    </h2>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-product-order')"
                  >
                    <img
                      src="images/orders.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.Product Orders") }}</p>
                    <h2 class="text-brand mb-0">
                      {{
                        this.detail.product_order_count
                          ? this.detail.product_order_count
                          : "00"
                      }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="services"
              role="tabpanel"
              aria-labelledby="services-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-4 col-12 mb-3">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/my-service')"
                  >
                    <img
                      src="images/all-services.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.All Services") }}</p>
                    <h2 class="text-brand mb-0">
                      {{
                        this.detail.my_service_counts
                          ? this.detail.my_service_counts
                          : "00"
                      }}
                    </h2>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-service-order')"
                  >
                    <img
                      src="images/job-services.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.Services Orders") }}</p>
                    <h2 class="text-brand mb-0">
                      {{
                        this.detail.service_order_counts
                          ? this.detail.service_order_counts
                          : "00"
                      }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "MyHome",
  components: {},
  data() {
    return {
      detail: {},
      token: localStorage.getItem("access_token"),
    };
  },
  mounted() {
    this.getDashBoard();
  },
  methods: {
    getDashBoard() {
      this.$api
        .getAPI({
          _action: "dashboard",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

