<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100">
      <div class="spx-modal-body mx-auto delete-modal">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body text-center p-0">
          <div class="font-18 py-3">{{ body }} ?</div>
        </div>
        <div class="modal-footer pb-0 px-0">
          <button
            type="button"
            class="btn btn-action btn-brand-1"
            id="delete-button"
            :disabled="showLoader"
            style=""
            v-on:click="onClick">
            <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>Yes
          </button>
          <button
            type="button"
            class="btn btn-danger btn-action btn-brand-2"
            id="cancel-button"
            style=""
            v-on:click="closeModal">
            No
          </button>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  data() {
    return {
      isShowModal: false,
      showLoader: false,
      title: "Alert",
      body: "Are you sure you want to delete",
      obj: {},
    };
  },
  mounted() {},
  methods: {
    showModal(title, body, obj) {
      this.title = title;
      this.body = body;
      this.obj = obj;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
  },
};
</script>