<template>
   <aside class="left-sidebar" id="mob_sidebar">
    <div class="scroll-sidebar no-right-sidebar">
      <nav class="sidebar-nav">
        <ul class="nav-bar pl-0" id="leftNav">
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_1"
              href="/home"
              :class="{ active: pageName.includes('home') }"
              ><i class="bx bxs-dashboard side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Dashboard") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_2"
              v-on:click="$router.push('/manage-products/' + mydetail.id)"
              :class="{
                active:
                  pageName.includes('manage-products') ||
                  pageName.includes('my-product') ||
                  pageName.includes('master-product') ||
                  pageName.includes('add-product') ||
                  pageName.includes('edit-product'),
              }"
              ><i class="bx bx-package side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Manage Products") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_3"
              v-on:click="$router.push('/manage-services/' + mydetail.id)"
              :class="{
                active:
                  pageName.includes('manage-services') ||
                  pageName.includes('my-service') ||
                  pageName.includes('master-service') ||
                  pageName.includes('add-service') ||
                  pageName.includes('edit-service'),
              }"
              ><i class="bx bx-briefcase-alt-2 side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Manage Services") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_4"
              v-on:click="$router.push('/manage-product-order')"
              :class="{
                active:
                  pageName.includes('manage-product-order') ||
                  pageName.includes('product-order-detail'),
              }"
              ><i class="bx bx-shopping-bag side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Manage Product Orders") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_5"
              v-on:click="$router.push('/manage-service-order')"
              :class="{
                active:
                  pageName.includes('manage-service-order') ||
                  pageName.includes('service-order-detail'),
              }"
              ><i class="bx bx-briefcase-alt side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Manage Service Orders") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_6"
              v-on:click="$router.push('/earnings')"
              :class="{
                active:
                  pageName.includes('earnings') ||
                  pageName.includes('all-withdrawal') ||
                  pageName.includes('request-for-withdrawal') ||
                  pageName.includes('withdrawal-request'),
              }"
            >
              <i class="bx bx-wallet-alt side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Earnings") }}</span></a
            >
          </li>
          <li class="nav-item cursor-pointer">
            <a
              class="nav-link menu_no_7"
              v-on:click="$router.push('/delivery-charges')"
              :class="{ active: pageName.includes('delivery-charges') }"
              ><i class="bx bx-purchase-tag side-nav-icon"></i
              ><span class="hide-menu">{{ $t("label.Setting") }}</span></a
            >
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      pageName: "",
      mydetail: {},
      token: localStorage.getItem("access_token"),
      currentTab: location.pathname.replace("/", ""),
    };
  },
  computed: {
    detail() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    this.meCall();
  },

  methods: {
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.mydetail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

