<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 text-md-left">
                  <h2 class="page-title mb-3 pb-0">
                    {{ $t("label.Setting") }}
                  </h2>
                </div>
                <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
              </div>
              <div class="card card-body form-style content-card">
                <Form class="form-style" @submit="save()">
                  <div class="row">
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label class=""
                          >{{ $t("label.Product Delivery Charges") }} (SAR)</label
                        >
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="shipping_charges"
                          name="shipping_charges"
                          v-model="detail.shipping_charges"
                          class="form-control filled"
                          rules="required:shipping charges|numeric|min_value:0"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="shipping_charges" class="validation-msg" />
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="form-group">
                        <label class="">{{
                          $t("label.Minimum Order Free Shipping")
                        }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="min-order_1"
                          name="min-order_1"
                          v-model="detail.min_order_value_for_free_shipping"
                          class="form-control filled"
                          rules="required:minimun order free sheeping|numeric|min_value:0"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="min-order_1" class="validation-msg" />
                    </div>

                    <div class="col-lg-6 col-sm-6 col-12 text-left mt-3">
                      <strong
                        ><span v-if="detail.product_commission_type == 'FIXED'">SAR</span
                        >{{ $t("label.Product Commision") }}:-
                        {{ detail.product_commission }}
                        <span v-if="detail.product_commission_type == 'PERCENT'"
                          >%</span
                        ></strong
                      >
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 text-left mt-3 ">
                      <strong
                        >{{ $t("label.Service Commision") }}:-
                        <span v-if="detail.service_commission_type == 'FIXED'">SAR </span
                          >{{ detail.service_commission }}
                        <span v-if="detail.service_commission_type == 'PERCENT'"
                          >%</span
                        ></strong
                      >
                    </div>

                    <div class="col-12 text-center mt-4 px-0">
                      <button id="save-button" class="btn btn-brand-1 mx-2">
                        {{ $t("label.Save") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 cancel mx-2"
                        v-on:click="$router.go(-1)"
                      >
                        {{ $t("label.Cancel") }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ProductDeliveryCharges",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res) {
            this.detail = res.info.setting;
          }
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "settings",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getDetail();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

