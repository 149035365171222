<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-9 text-md-left">
            <h2 class="page-title mb-0 pb15">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t("label.All Withdrawal") }}
            </h2>
          </div>
          <div class="col-md-3 text-right d-md-flex justify-content-end mb-3">
            <div class="input-group page-cont-search">
              <span class="input-group-text"><i class="bx bx-search"></i></span>
              <input
                type="text"
                class="form-control"
                name=""
                v-on:keyup.enter="getWithdrawal(0)"
                :placeholder="$t('label.search here')"
                v-model="searchObj.keyword"
              />
            </div>
          </div>
        </div>

        <table
          class="table table-style-1 table-responsive mb-4 table table-no-responsive-md view-order"
        >
          <thead>
            <tr>
              <th scope="col">{{ $t('label.Withdrawal Request & Date Time') }}</th>
              <th scope="col" class="serive-name">{{ $t('label.Payment Amount') }}</th>
              <!-- <th scope="col">Withdrwal Method</th> -->
              <th scope="col">{{ $t('label.Withdrawal Status') }}</th>
              <th scope="col">{{ $t('label.Action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in list" :key="index">
              <td>
                <span class="font-medium">{{ $t('label.Withdrawal Number') }}  #{{ data.withdrawal_req_no }}</span
                ><br />
                <span>{{ data.created_at }}</span>
              </td>
              <td>SAR {{ data.total_amount }}</td>
              <!-- <td>Electronic Wallet</td> -->
              <td>
                <span
                  :class="
                    data.status == 'PENDING'
                      ? 'text-warning'
                      : data.status == 'REJECTED'
                      ? 'text-danger'
                      : 'text-success'
                  "
                  >{{ data.status }}</span
                >
              </td>
              <td>
                <button
                  class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="View"
                  v-on:click="$router.push('/withdrawal-request/' + data.id)"
                >
                  <i class="bx bx-show"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mx-0 mb-4">
      <div class="col-lg-12 mt-5">
        <div class="page-bottom-pagination">
          <Pagination
            @page-change="pageChange"
            @items-per-page-change="itemsPerPageChange"
            ref="listPagination"
          />
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getWithdrawal(0)" ref="errorComponent" />
  </div>
</template>

<script>
import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "AllWitdrawal",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      searchObj: {
        keyword: "",
      },
      list: [],
      loader: false,
      offset: 0,
      itemsPerPage: "",
    };
  },
  mounted() {
    this.getWithdrawal(0);
  },
  methods: {
    pageChange(offset) {
      this.getWithdrawal(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getWithdrawal(0);
    },
    getWithdrawal(offset) {
      this.loader = true;
      // this.$refs.errorComponent.updateLoader(true);
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      if (offset == 0) {
        this.list = [];
      }
      obj.offset = offset;
      obj.status = "ALL";
      this.$api
        .getAPI({
          _action: "withdraw-requests",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "DD/MM/YYYY hh:mm a"
            );
          }
          this.list = res.list;
          this.offset = res.next_offset;

          if (this.offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.Withdrawal Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
  },
};
</script>

