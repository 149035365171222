<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 text-md-left">
                  <h2 class="page-title mb-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i></a
                    >{{ $t("label.Add Products") }}
                  </h2>
                </div>
                <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
              </div>
              <Form @submit="save()">
                <div class="card card-body form-style content-card">
                  <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 text-center">
                      <label for="">{{ $t("label.Product image") }}</label>
                      <div class="custom-select-img">
                        <div class="file-upload">
                          <div class="image-upload-wrap p-3 mb-4">
                            <input
                              type="file"
                              name="img[]"
                              id="pickFile"
                              class="file-upload-input"
                              v-on:change="selectedFile($event)"
                              accept="image/*"
                            />
                            <div class="drag-text text-center">
                              <img
                                id="preview"
                                :src="
                                  imageObj.image && imageObj.image != null
                                    ? imageObj.image
                                    : '/images/Imageplace-holder.svg'
                                "
                                width="40"
                              />
                              <h3 class="heading font-20">{{ $t("label.Upload a image") }}</h3>
                              <p class="font-12 text-secondary mb-1" for="pickFile">
                                PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                              </p>
                              <p class="font-12 text-secondary mb-0">{{ $t("label.(Optional)") }}</p>
                            </div>
                          </div>
                          <div class="file-upload-content">
                            <img
                              class="file-upload-image mb-3"
                              src="#"
                              alt="your image"
                            />
                            <div class="image-title-wrap">
                              <button
                                type="button"
                                onclick="removeUpload()"
                                class="remove-image"
                              >
                                <i class="bx bx-x mr-2" aria-hidden="true"></i>
                                <span class="image-title">{{ $t("label.change Image") }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-lg-4 col-md-6 mx-auto px-0">
                        <div class="form-group">
                          <label class="">{{ $t("label.Product Name") }}</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            id="fname"
                            name="fname"
                            v-model="detail.title"
                            class="form-control filled"
                            rules="required:name"
                            :validateOnInput="true"
                          />
                        </div>
                      </div>
                    </div>
                    <ErrorMessage name="fname" class="validation-msg" />
                  </div>

                  <div class="row mb-md-4 mb-3">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Product Description") }}</label>
                        <span class="text-danger">*</span>
                        <textarea
                          id="description"
                          name="description"
                          class="form-control filled"
                          style="height: 70px"
                          v-model="detail.description"
                          cols="30"
                          rows="4"
                          rules="required:description"
                          :validateOnInput="true"
                        ></textarea>
                      </div>
                      <ErrorMessage name="description" class="validation-msg" />
                    </div>
                    <div class="col-md-4">
                      <label>{{ $t("label.Category") }}</label>
                      <span class="text-danger">*</span>
                      <!-- <div class="selectParent mb-md-0 mr-md-3"> -->
                      <Field
                        as="select"
                        class="selectParent mb-md-0 mr-md-3"
                        v-model="detail.category_id"
                        name="category"
                        rules="required:category,true"
                        v-on:change="getSubCategory()"
                      >
                        <option value="">{{ $t("label.Select") }}</option>
                        <option
                          v-for="(data, index) in categoryList"
                          :key="index"
                          :value="data.id"
                        >
                          {{ data.title }}
                        </option>
                      </Field>
                      <!-- </div> -->
                      <ErrorMessage name="category" class="validation-msg mt-2" />
                    </div>
                    <div class="col-md-4">
                      <label>{{ $t("label.Sub-Category") }}</label>
                      <span class="text-danger">*</span>
                      <!-- <div class="selectParent mb-md-0 mb-4 mr-md-3"> -->
                      <Field
                        as="select"
                        class="selectParent mb-md-0 mb-4 mr-md-3"
                        v-model="detail.sub_category_id"
                        name="subcategory"
                        rules="required:subcategory,true"
                      >
                        <option value="">{{ $t("label.Select") }}</option>
                        <option
                          v-for="(data, index) in subCategoryList"
                          :key="index"
                          :value="data.id"
                        >
                          {{ data.title }}
                        </option>
                      </Field>
                      <!-- </div> -->
                      <ErrorMessage name="subcategory" class="validation-msg mt-2" />
                    </div>
                    <div class="col-md-4 form-group">
                      <label>{{ $t("label.Price") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="text"
                        id="price"
                        name="price"
                        v-model="detail.price"
                        class="form-control filled"
                        rules="required:price"
                        :validateOnInput="true"
                      />
                      <ErrorMessage name="price" class="validation-msg mt-2" />
                    </div>

                    <div class="col-12 my-4">
                      <div
                        class="custom-control custom-checkbox mr-md-4 mb-md-0 mb-2 d-inline"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <input
                          type="checkbox"
                          id="details"
                          name="details"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label font-medium" for="details"
                          >{{ $t("label.Details") }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row collapse" id="collapseExample">
                    <div
                      class="col-md-4"
                      v-for="(data, index) in attributeList"
                      :key="index"
                    >
                      <div class="form-group">
                        <label class="">{{ data.title }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="title"
                          name="title"
                          v-model="data.values"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <hr />
                  </div>

                  <div class="form-group">
                    <label class="text-secondary-2 font-14 d-block">{{ $t("label.Out of stock") }}</label>
                    <div class="custom-control custom-switch d-inline mr-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="shwitch_1"
                        true-value="Y"
                        false-value="N"
                        v-model="detail.is_out_of_stock"
                      />
                      <label class="custom-control-label" for="shwitch_1"></label>
                    </div>
                  </div>
                  <div class="text-center">
                    <button id="save-button" class="btn btn-brand-1 mx-2">{{ $t("label.Save") }}</button>
                    <button
                      type="button"
                      class="btn btn-brand-1 cancel mx-2"
                      onclick="goBack()"
                    >
                    {{ $t("label.Cancel") }}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddProduct",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {
        is_out_of_stock: "N",
        attributes: [],
      },
      file: undefined,
      imageObj: {},
      categoryList: [],
      subCategoryList: [],
      attributeList: [],
    };
  },
  mounted() {
    this.getCategory();
    this.getAttributes();
  },
  methods: {
    getCategory() {
      this.$api
        .getAPI({
          _action: "categories/product",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },
    getSubCategory() {
      this.detail.sub_category_id = "";
      if (this.detail.category_id) {
        this.$api
          .getAPI({
            _action: "categories/product/" + this.detail.category_id,
          })
          .then((res) => {
            this.subCategoryList = res.list;
          })
          .catch(() => {});
      }
    },
    save() {
      for (var i in this.attributeList) {
        // console.log("list", this.attributeList[i]);
        if (this.attributeList[i].values) {
          var obj = {
            code: this.attributeList[i].code,
            value: this.attributeList[i].values,
          };

          this.detail.attributes.push(obj);
        }
      }
      // console.log("att", this.detail.attributes);
      this.$api
        .postAPI({
          _action: "product",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.uploadImage(res.product_id);
        })
        .catch(() => {});
    },
    getAttributes() {
      this.$api
        .getAPI({
          _action: "master/product/attributes",
        })
        .then((res) => {
          this.attributeList = res.list;
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$router.go(-1);
      } else {
        this.$api
          .uploadImageAPI({
            _action: "product/" + id + "/image",
            _key: "image",
            _file: this.file,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          });
      }
    },
  },
};
</script>

