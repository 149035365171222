import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      AreaObj:{},
      isShowSidebar: false,
      summary: { company: [], businessGroup: [], webinars: [], activity: [] },
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
      console.log("payload",state.userInfo);
    },
    setArea(state, payload) {
      
      state.AreaObj = payload;
      // console.log("payload",state.AreaObj);
    },
    setSummary(state, payload) {
      // console.log(payload)
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getArea(state) {
      return state.AreaObj;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      // console.log("gete")
      // console.log(state.summary)
      return state.summary;
    },
  },
  actions: {},
});

export default store;
