<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 text-md-left">
                  <h2 class="page-title mb-3">{{ $t("label.Earnings") }}</h2>
                </div>
                <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
              </div>
              <div class="row justify-content-center mb-4">
                <div class="col-md-5 text-md-left text-center">
                  <div class="card mb-3 cursor-pointer" v-on:click="$router.push('/pay-to-admin')">
                    <div class="card-body text-center">
                      <h1 class="text-secondary">
                        <i class="bx bx-wallet-alt"></i>
                      </h1>
                      <h3 class="text-brand">
                        SAR {{ this.pay_to_admin ? this.pay_to_admin.toFixed(2) : "00" }}
                      </h3>
                      <p>{{ $t("label.Pay to Admin") }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 text-md-left text-center">
                  <div class="card mb-3 cursor-pointer" v-on:click="$router.push('/available-balance')">
                    <div class="card-body text-center">
                      <h1 class="text-secondary">
                        <i class="bx bx-wallet-alt"></i>
                      </h1>
                      <h3 class="text-brand">
                        SAR {{ this.availBalance ? this.availBalance.toFixed(2) : "00" }}
                      </h3>
                      <p>{{ $t("label.Available Balance") }}</p>
                    </div>
                  </div>
                  <div class="d-md-flex justify-content-between">
                    <button
                      class="btn btn-light px-4 text-brand mb-md-0 mb-2"
                      v-on:click="$router.push('/request-for-withdrawal')"
                    >
                      <i class="bx bx-export ml-n2"></i
                      >{{ $t("label.Request for Withdrawal") }}
                    </button>
                    <button
                      class="btn btn-light text-brand"
                      v-on:click="$router.push('/all-withdrawal')"
                    >
                      {{ $t("label.All Withdrawal") }}
                      <i class="bx bx-arrow-from-left"></i>
                    </button>
                  </div>
                </div>
              </div>

              <table
                class="table table-style-1 table-responsive mb-4 table table-no-responsive-md view-order"
              >
                <thead>
                  <tr>
                    <th scope="col">{{ $t("label.All Earnings") }}</th>
                    <th scope="col">{{ $t("label.Withdrawal Status") }}</th>
                    <th scope="col" class="serive-name">
                      {{ $t("label.Total Order Payment") }}
                    </th>

                    <th scope="col">{{ $t("label.Your Earnings") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in request" :key="index">
                    <td>
                      <span class="font-medium"
                        >{{ $t("label.Withdrawal Number") }} #{{
                          data.withdrawal_req_no
                        }}</span
                      ><br />
                      <span>{{ data.created_at }}</span>
                    </td>
                    <td>
                      <span
                        :class="
                          data.status == 'PENDING'
                            ? 'text-warning'
                            : data.status == 'REJECTED' || data.status == 'CANCELLED'
                            ? 'text-danger'
                            : 'text-success'
                        "
                        >{{ data.status }}</span
                      >
                    </td>
                    <td>SAR {{ data.total_amount }}</td>
                    <td>SAR {{ data.earnings }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  name: "EarningsPage",
  components: {},
  data() {
    return {
      detail: {},
      availBalance: "",
      pay_to_admin: "",
      request: [],
    };
  },
  mounted() {
    this.getEarnings();
  },
  methods: {
    getEarnings() {
      this.$api
        .getAPI({
          _action: "earnings",
        })
        .then((res) => {
          this.detail = res;
          this.availBalance = res.available_balance;
          this.pay_to_admin = res.pay_to_admin;
          for (var i in res.recent_withdrawal_requests) {
            res.recent_withdrawal_requests[i].created_at = moment(
              res.recent_withdrawal_requests[i].created_at
            ).format("DD/MM/YYYY hh:mm a");
          }
          this.request = res.recent_withdrawal_requests;
        })
        .catch(() => {});
    },
  },
};
</script>

