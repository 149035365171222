<template>
  <div>
    <!-- <SignupHeader /> -->
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div
            class="row justify-content-md-center align-items-center min-vh-100"
          >
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center">
                <div class="col-lg-9 py-4">
                  <div class="px-lg-5 px-0 py-md-4 card">
                    <div class="card-body pb-0">
                      <div class="text-center">
                        <img
                          src="images/gardning-logo.svg"
                          width="180"
                          height="37"
                          class="img-fluid mb-4 mx-auto"
                        />
                        <p class="font-medium font-18">Sign In</p>
                      </div>
                      <form class="form-style floating-form">
                        <Form @submit="signup()">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="name"
                                  v-model="detail.name"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                                <label for="fname">Name</label>
                                <i class="bx bx-user icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                              name="name"
                              class="validation-msg"
                            />
                            </div>
                            
                            <div class="col-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  rules="email"
                                  :validateOnInput="true"
                                />
                                <label for="email">Email</label>
                                <i class="bx bx-envelope icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                                name="email"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                />
                                <label for="phone">Phone</label>
                                <i class="bx bx-phone icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                                name="phone"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="address"
                                  id="map"
                                  classname="form-control"
                                  :placeholder="$t('label.Type Here')"
                                  v-model="detail.address"
                                  v-on:placechanged="getAddressData"
                                  types="(cities)"
                                >
                                </vue-google-autocomplete>
                                <label for="city">Address</label>
                                <i class="bx bx-map icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                                name="city"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group mb-4 with-icon">
                                <Field
                                  :type="newPasswordFieldType"
                                  id="password"
                                  name="password"
                                  v-model="detail.password"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:password|minLength:6|password"
                                  :validateOnInput="true"
                                />
                                <label for="password">Password</label>
                                <i class="bx bx-lock icon"></i>
                                <span class="text-danger">*</span>
                                <a
                                  class="psw-icon"
                                  v-on:click="switchVisibilityNew"
                                  ><img :src="imageNew" width="20"
                                /></a>
                              </div>
                              <ErrorMessage
                                name="password"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group mb-4 with-icon">
                                <Field
                                  :type="passwordFieldType"
                                  id="conf_password"
                                  name="confirm_password"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:confirm password|confirmed:@password"
                                  :validateOnInput="true"
                                />
                                <label for="conf_password"
                                  >Confirm Password</label
                                >
                                <i class="bx bx-lock icon"></i>
                                <span class="text-danger">*</span>
                                <a
                                  class="psw-icon"
                                  v-on:click="switchVisibility"
                                  ><img :src="image" width="20"
                                /></a>
                              </div>
                              <ErrorMessage
                                name="confirm_password"
                                class="validation-msg"
                              />
                            </div>
                          </div>

                          <button
                            class="btn btn-brand-1 btn-block mb-1"
                            id="signup-button"
                          >
                            Signup
                          </button>
                        </Form>
                      </form>
                    </div>
                    <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Already have an account?
                        <a
                          href="login"
                          class="ml-2 text-primary font-medium" style="color:#00BB59 !important;">
                          Login</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!--Reset password-->
    <div
      class="modal fade"
      id="ResetPsw"
      tabindex="-1"
      aria-labelledby="ResetPsw"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-small">
        <div class="modal-content form-style">
          <div class="modal-header px-md-5">
            <h5 class="modal-title" id="">Reset Password</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="images/close-circle-o.svg" width="30" />
            </button>
          </div>
          <div class="modal-body px-md-4">
            <div class="form-group d-flex align-items-center">
              <label>New Password</label>
              <img src="images/lock-icon.svg" width="20" class="mr-1" />
              <input type="text" class="form-control password" />
              <a href="javascript:void(0)" onclick="hideShowPsw()"
                ><img src="images/eye-icon.svg" width="20" class="mr-1"
              /></a>
            </div>
            <div class="form-group d-flex align-items-center">
              <label>Confirm Password</label>
              <img src="images/lock-icon.svg" width="20" class="mr-1" />
              <input type="text" class="form-control password" />
              <a href="javascript:void(0)" onclick="hideShowPsw()"
                ><img src="images/eye-icon.svg" width="20" class="mr-1"
              /></a>
            </div>

            <div class="mb-3 text-center">
              <button
                type="button"
                class="btn btn-lg btn-brand-1 cancel"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-lg btn-brand-1">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
// import SignupHeader from "@/components/signup-header.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "SignUp",
  components: {
    Form,
    Field,
    ErrorMessage,
    // SignupHeader,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      id: this.$route.params.id,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      latitude: "",
      city: "",
      longitude: "",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      industryList: [],
      detail: {
        dial_code: "+91",
        country_code: "IN",
      },
    };
  },
  mounted() {
    //floating form
    this.$refs.address.focus();

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getAddressData: function (addressData) {
      this.detail.city_name = addressData.locality;
      console.log(this.detail.city_name);
    },
    signup() {
      this.$api
        .postAPI({
          _action: "signup",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
          _buttonId: "signup-button",
        })
        .then(() => {
          this.$router.push("/otp");
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>

