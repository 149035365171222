<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto p-0">
        <slot></slot>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "TextModal",
  data() {
    return {
      isShowModal: false,
    };
  },
  mounted() {},
  methods: {
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>