<template>
  <main role="main">
        <Header />
    <div
      class="d-lg-flex"
      :class="isShowSidebar ? '' : 'no-right-sidebar'"
      id="main-wraper"
    >
      <Menu />
  
      <div class="page-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </main>
</template>

<script>
// import RightSidebar from "@/components/rightsidebar";
import Header from "@/components/header.vue";
import Menu from "@/components/sidebar.vue";

export default {
  name: "MainView",
  components: {
    // RightSidebar,
    Menu,
    Header,
  },
  data() {},
  computed: {
    isShowSidebar() {
      return this.$store.getters.getShowSidebar;
    },
  },
  methods: {},
};
</script>