<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-md-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t('label.Notifications') }}
            </h2>
          </div>
          <!-- <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getExpertList(0)"
              :placeholder="$t('label.search here')"
              v-model="searchObj.keyword"
            />
          </div> -->
        </div>
        <div class="card rounded-l border-0 shadow">
          <div
            class="card-body border-bottom expert-list px-1 py-2"
            v-for="(data, index) in notificationList"
            :key="index"
          >
            <div class="row">
              <div class="col-md-8 d-flex align-items-center mb-0">
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.title }}
                  </p>
                  <p class="font-weight-light font-15 text-secondary mb-1 font-xs-12">
                    {{ data.message }}
                  </p>
                  <p class="font-weight-light font-15 text-secondary mb-0 font-xs-12">
                    {{ data.ago_time }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getNotification(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <div
    style="text-align: center; border-radius: 16px"
    class="col-12 pb-3"
    v-show="searchObj.offset != -1 && loader == false"
  >
    <a
      id="load-button"
      class="txt-success cursor-pointer mb-3"
      v-on:click="getNotification(offset)"
      style="max-width: 110px"
    >
      Load More
    </a>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
export default {
  name: "NotificationList",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      searchObj: {
        offset: 0,
      },
      notificationList: [],
    };
  },
  mounted() {
    this.getNotification(0);
  },
  methods: {
    getNotification(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "DD MMM YYYY | HH:mm A"
            );
            this.notificationList.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t('label.No notification available'));
            this.$refs.errorComponent.updateLoader(false);
          }
          //   this.notificationList = res.list;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    // getUnreadCount() {
    //   this.$api
    //     .getAPI({
    //       _action: "notification/count",
    //     })
    //     .then(() => {})
    //     .catch(() => {});
    // },
    unreadNotification(id) {
      this.$api
        .putAPI({
          _action: "notification/" + id,
        })
        .then(() => {
          location.reload();
        })
        .catch(() => {});
    },
  },
};
</script>

