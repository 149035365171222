<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row align-items-center">
            <div class="col-md-6 text-md-left">
                <h2 class="page-title mb-md-0">Manage Products</h2>
            </div>
        </div>
          <div
            class="row justify-content-center align-items-center"
            style="min-height: 40vh"
          >
            <div class="col-lg-3 col-md-4 col-12 mb-md-0 mb-3">
              <div
                class="card p-3 shadow-sm text-center border-0 card-hover" style="cursor: pointer;"
                v-on:click="$router.push('/master-product')"
              >
                <img
                  src="/images/all-products.svg"
                  width="92"
                  alt=""
                  class="mx-auto mb-3"
                />
                <p class="font-18 mb-2">{{ $t("label.Gardening Catelog Products") }}</p>
                <h2 class="text-brand mb-0">{{this.detail.master_product_counts}}</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-12">
              <div
                class="card p-3 shadow-sm text-center border-0 card-hover" style="cursor: pointer;"
                v-on:click="$router.push('/my-product')"
              >
                <img
                  src="/images/all-services.svg"
                  width="92"
                  alt=""
                  class="mx-auto mb-3"
                />
                <p class="font-18 mb-2">{{ $t("label.My Products") }}</p>
                <h2 class="text-brand mb-0">{{this.detail.my_product_counts}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
    name:"ManageProducts",
    data() {
        return {
          detail:{}
        }
    },
    mounted(){
      this.getProduct()
    },
    methods:{
      getProduct() {
      this.$api
        .getAPI({
          _action: "product/counts",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    }

};
</script>

