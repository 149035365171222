<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-md-0">
              <a
                href="javascript:void(0)"
                v-on:click="$router.go(-1)"
                class="back-arrow"
              >
                <i class="bx bx-arrow-back"></i></a
              >{{ $t('label.Withdrawal Detail') }}
            </h2>
          </div>
          <!-- <div
            class="col-md-6 text-right d-md-flex justify-content-end mb-3"
          ></div> -->
        </div>
        <div class="card card-body content-card form-style">
          <div class="row mb-md-4 mb-3 justify-content-center">
            <div class="col-lg-12">
              <div class="row mb-md-4 mb-3">
                <div class="col-md-12 mb-4">
                  <div class="alert alert-success" role="alert">
                    {{ $t('label.The money will be credited to your account within 1 minute to 3 working days') }}
                  </div>
                  <div class="text-brand">
                    <span class="font-20"
                      ><i class="bx bx-time-five align-text-bottom"></i
                    ></span>
                    {{ $t('label.Process may take up to 24 hours_Please contact us if the withdrawal is not completed within this period') }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="font-14 text-secondary">{{ $t('label.Payment Amount') }}</label>
                  <p>SAR {{ detail.total_amount }}</p>
                </div>
                <!-- <div class="col-md-6" >
                  <label class="font-14 text-secondary"
                    >Withdrawal Method</label
                  >
                  <p>Electronic Wallet</p>
                </div> -->
                <div class="col-md-6">
                  <label class="font-14 text-secondary">{{ $t('label.Date') }} &amp; {{ $t('label.Time') }}</label>
                  <p>{{ detail.created_at }}</p>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="font-14 text-secondary"
                      >{{ $t('label.Withdrawal Request ID') }}</label
                    >
                    <p>{{ detail.withdrawal_req_no }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="font-14 text-secondary">{{ $t('label.Status') }}</label>
                    <p>{{ detail.status }}</p>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-brand-1"
                    v-on:click="$refs.textModal.showModal()"
                    v-if="detail.status != 'CANCELLED'"
                  >
                    {{ $t('label.Cancel Request') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-brand-1 cancel"
                    v-on:click="$router.go(-1)"
                  >
                    {{ $t('label.Back') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button
            type="button"
            class="close"
            v-on:click="$refs.textModal.closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{ $t('label.Reason') }}</h5>
        </div>
        <Form @submit="cancelRequest()">
          <div class="modal-body">
            <div class="floating-form-group">
              <label class="font-medium">{{ $t('label.Reason') }}</label>

              <Field
                id="reason"
                name="reason"
                v-model="reason"
                v-slot="{ field }"
                class="form-control"
                rules="required:reason"
                :validateOnInput="true"
              >
                <textarea
                  type="text-area"
                  class="form-control"
                  v-bind="field"
                />
              </Field>
            </div>
            <ErrorMessage name="reason" class="validation-msg mt-2" />
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn btn-success"
              id="save-button"
              style="width: 18%; border-radius: 5px"
            >
              {{ $t('label.Save') }}
            </button>
            <button
              type="button"
              class="fil-btn btn-danger"
              id="cancel-button"
              style="width: 18%; border-radius: 5px"
              v-on:click="$refs.textModal.closeModal()"
            >
            {{ $t('label.Cancel') }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "../../components/TextModal.vue";
import moment from "moment";
export default {
  name: "WithdrawalRequest",

  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      reason: "",
    };
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "withdraw-request/" + this.id,
        })
        .then((res) => {
          res.info.created_at = moment(res.info.created_at).format("LLL");
          this.detail = res.info;
        });
    },
    cancelRequest() {
      var obj = {};
      obj.status = "REJECTED";
      obj.reason = this.reason;
      this.$api
        .putAPI({
          _action: "withdraw-request/cancel/" + this.id,
          _body: obj,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.textModal.closeModal();
        });
    },
  }
};
</script>

