<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t("label.Edit Service") }}
            </h2>
          </div>
          <!-- <div class="table-style-1 p-3 mb-3"> -->
          <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
        <!-- </div> -->
        </div>
        <div class="card card-body content-card form-style">
          <div class="row mb-md-4 mb-3 justify-content-center">
            <div class="col-lg-12">
              <Form @submit="save()">
                <div class="row mb-md-4 mb-3">
                  <div class="col-md-12 mb-4">
                    <p class="font-medium">{{ $t("label.Service Image") }}</p>

                    <div class="d-flex align-items-center overflow-auto pb-2">
                      <img
                        src="https://img10.joybuy.com/N0/s560x560_jfs/t1/81506/23/4684/203990/5d2dc6f7Eb11b4d60/a4f80911427e4003.jpg"
                        class="object-cover mr-3"
                        width="200"
                        height="150"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label class="font-medium">{{ $t("label.Category") }}</label>
                    <p>{{ detail?.service?.category?.title }}</p>
                  </div>

                  <div class="col-md-9">
                    <div class="form-group">
                      <label class="font-medium">{{
                        $t("label.Service Description")
                      }}</label>
                      <p>
                        {{ detail?.service?.description }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="font-medium">{{ $t("label.Visit Price") }}</label>

                    <Field
                      type="text"
                      class="form-control"
                      v-model="detail.visit_charges"
                      style="width: 100px"
                      name="visit_charges"
                      rules="required:visit charges"
                    />
                    <ErrorMessage name="visit_charges" class="validation-msg mt-2" />
                  </div>
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="calculation"
                        true-value="Y"
                        false-value="N"
                        v-model="detail.has_allow_self_calculation"
                        @change="updateFlag()"
                      />
                      <label class="custom-control-label" for="calculation">{{
                        $t("label.Allow Self Calculation")
                      }}</label>
                    </div>
                  </div>
                  <div
                    class="col-12 mt-2"
                    v-if="
                      detail.has_allow_self_calculation == 'Y' &&
                      detail.master_attributes.grass_trimmings.length > 0
                    "
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="has_grass_trimming"
                        true-value="Y"
                        false-value="N"
                        v-model="detail.has_grass_trimming"
                      />
                      <label class="custom-control-label" for="has_grass_trimming">{{
                        $t("label.Has Grass Trimming")
                      }}</label>
                    </div>
                  </div>

                  <div
                    class="col-12 mt-2 table-responsive"
                    v-if="
                      detail.has_allow_self_calculation == 'Y' &&
                      detail.has_grass_trimming == 'Y'
                    "
                  >
                    <table class="table table-style-1 border rounded">
                      <thead>
                        <tr>
                          <th>{{ $t("label.Category") }}</th>
                          <th>{{ $t("label.Title") }}</th>
                          <th>{{ $t("label.Range From") }}</th>
                          <th>{{ $t("label.Range To") }}</th>
                          <th>{{ $t("label.Price") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="detail.has_grass_trimming == 'Y'">
                          <tr
                            v-for="(item, index) in this.detail.master_attributes
                              .grass_trimmings"
                            :key="item"
                          >
                            <td>{{ item.type }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.range_from }}</td>
                            <td>{{ item.range_to }}</td>
                            <td>
                              <Field
                                type="text"
                                class="form-control"
                                style="width: 120px"
                                :name="'price_grass_' + index"
                                v-model="item.price"
                                rules="required:price"
                              />
                              <ErrorMessage
                                :name="'price_grass_' + index"
                                class="validation-msg mt-2"
                              />
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="col-12 mt-2"
                    v-if="
                      detail.has_allow_self_calculation == 'Y' &&
                      detail.master_attributes.tree_trimmings.length > 0
                    "
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="has_tree_trimming"
                        true-value="Y"
                        false-value="N"
                        v-model="detail.has_tree_trimming"
                      />
                      <label class="custom-control-label" for="has_tree_trimming">{{
                        $t("label.Has Tree Trimming")
                      }}</label>
                    </div>
                  </div>

                  <div
                    class="col-12 mt-2 table-responsive"
                    v-if="
                      detail.has_allow_self_calculation == 'Y' &&
                      detail.has_tree_trimming == 'Y'
                    "
                  >
                    <table class="table table-style-1 border rounded">
                      <thead>
                        <tr>
                          <th>{{ $t("label.Category") }}</th>
                          <th>{{ $t("label.Title") }}</th>
                          <th>{{ $t("label.Price") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="detail.has_tree_trimming == 'Y'">
                          <tr
                            v-for="(item, index) in this.detail.master_attributes
                              .tree_trimmings"
                            :key="item"
                          >
                            <td>{{ item.type }}</td>
                            <td>{{ item.title }}</td>
                            <td>
                              <Field
                                type="text"
                                class="form-control"
                                style="width: 120px"
                                :name="'price_tree_' + index"
                                rules="required:price"
                                v-model="item.price"
                              />
                              <ErrorMessage
                                :name="'price_tree_' + index"
                                class="validation-msg mt-2"
                              />
                            </td></tr
                        ></template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 mt-4 text-center px-0">
                  <button class="btn btn-brand-1">
                    {{ $t("label.Save") }}</button
                  >&nbsp;&nbsp;
                  <button
                    type="button"
                    class="btn btn-brand-1 cancel"
                    v-on:click="$router.go(-1)"
                  >
                    {{ $t("label.Cancel") }}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditService",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: { service: { has_manual_calculation: "N" } },
      is_checked: false,
      master_attributes: [],
    };
  },
  mounted() {
    this.getServiceDetail();
  },
  methods: {
    checked() {
      return (this.is_checked = !this.is_checked);
    },
    save() {
      if (this.detail.master_attributes.grass_trimmings) {
        this.detail.grass_trimmings = this.detail.master_attributes.grass_trimmings;
      }
      if (this.detail.master_attributes.tree_trimmings) {
        this.detail.tree_trimmings = this.detail.master_attributes.tree_trimmings;
      }

      this.$api
        .putAPI({
          _action: "service/" + this.id,
          _body: this.detail,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$route.go(-1);
        })
        .catch(() => {});
    },
    updateFlag() {
      setTimeout(() => {
        if (this.detail.has_allow_self_calculation == "N") {
          this.detail.has_grass_trimming = "N";
          this.detail.has_tree_trimming = "N";
        }
      }, 500);
    },
    getServiceDetail() {
      this.$api
        .getAPI({
          _action: "service/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.master_attributes = [];
          if (res.info && res.info.master_attributes) {
            if (res.info.master_attributes.grass_trimmings.length > 0) {
              for (
                var a = 0;
                a < res.info.master_attributes.grass_trimmings.length;
                a++
              ) {
                this.master_attributes.push(
                  res.info.master_attributes.grass_trimmings[a]
                );
              }
            }
            if (res.info.master_attributes.tree_trimmings.length > 0) {
              for (var b = 0; b < res.info.master_attributes.tree_trimmings.length; b++) {
                this.master_attributes.push(res.info.master_attributes.tree_trimmings[b]);
              }
            }
          } else {
            this.detail.master_attributes = {};
            this.detail.master_attributes.grass_trimmings = [];
            this.detail.master_attributes.tree_trimmings = [];
          }
        })
        .catch(() => {});
    },
  },
};
</script>

