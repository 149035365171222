<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 col-sm-6">
                  <h2 class="page-title mb-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Pay to Admin") }}
                  </h2>
                </div>
                <div
                  class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-md-0 mb-3"
                ></div>
              </div>
              <div
                class="row mb-3 align-items-center justify-content-between filter-options"
              >
                <!-- <div class="col-lg-3 col-md-4 d-md-flex mb-md-0 mb-2">
                  <div class="input-group page-cont-search">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      v-on:keyup.enter="getServiceList(0)"
                      :placeholder="$t('label.search here')"
                      v-model="searchObj.keyword"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-8 text-right d-md-flex align-items-center">
                  
                </div> -->
              </div>

              <table class="table table-style-1 table-responsive" id="sorting_tbl">
                <thead>
                  <tr>
                    <th>{{ $t("label.Order ID") }}</th>
                    <th>{{ $t("label.Order Type") }}</th>
                    <th>{{ $t("label.Date & Time") }}</th>
                    <th>{{ $t("label.Preferred Date") }}</th>
                    <th>{{ $t("label.Order Amount") }}</th>
                    <th>{{ $t("label.Commission") }}</th>
                    <th>{{ $t("label.Action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in serviceList" :key="index">
                    <td>{{ data.order_no }}</td>
                    <td>{{ data.order_type }}</td>
                    <td>{{ data.created_at }}</td>
                    <td>
                      <div>{{ data.preferred_date }}</div>
                      <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                        $t("label.06 AM TO 10 AM")
                      }}</span>
                      <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                        $t("label.10 AM TO 02 PM")
                      }}</span>
                      <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                        $t("label.02 PM TO 06 PM")
                      }}</span>
                      <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                        $t("label.06 PM TO 10 PM")
                      }}</span>
                    </td>
                    <td>{{ data.net_amount }} SAR</td>
                    <td>{{ data.admin_amount }} SAR</td>
                    <td>
                      <button
                        v-if="data.order_type == 'SERVICE'"
                        class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                        v-on:click="$router.push('/service-order-detail/' + data.id)"
                      >
                        <i class="bx bx-show"></i>
                      </button>

                      <button
                        v-if="data.order_type == 'PRODUCT'"
                        class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                        v-on:click="$router.push('/product-order-detail/' + data.id)"
                      >
                        <i class="bx bx-show"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getServiceList(0)" ref="errorComponent" />
            </div>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5">
              <div class="page-bottom-pagination">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyService",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      serviceList: [],
      searchObj: {
        keyword: "",
      },
      loader: true,
      offset: 0,
      itemsPerPage: "",
    };
  },
  mounted() {
    this.getServiceList(0);
  },
  methods: {
    pageChange(offset) {
      this.getServiceList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getserviceList(0);
    },
    getServiceList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }

      obj.offset = offset;
      this.$api
        .getAPI({
          _action: "cod/orders",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.serviceList = res.list;
          //   this.offset = res.next_offset;
          //   if (this.offset == 0) {
          //     if (this.$refs.listPagination) {
          //       if (res.count != null && res.count > 0) {
          //         this.$refs.listPagination.setTotalCount(res.count);
          //       } else {
          //         this.$refs.listPagination.setTotalCount(0);
          //       }
          //     }
          //   }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.No record found"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
  },
};
</script>

