<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 col-6 text-md-left text-center">
            <h2 class="page-title mb-0  pl-zero">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t("label.Order Detail") }}
            </h2>
          </div>
          <div class="col-md-6 col-6 text-right d-md-flex justify-content-end">
            <h4 class="font-medium font-20">{{ $t("label.Order Id: #") }} {{ detail.id }}</h4>
          </div>
        </div>
        <div class="table-style-1 p-3 mb-3">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-12">
            <h4 class="mb-1 font-16">
              <i class="bx bxs-user mr-1"></i>
              {{ detail?.user?.name }}
            </h4>
            <p>
              <i class="bx bxs-phone mr-1"></i>
              {{ detail?.user?.phone_number }},<br />
              <span><i class="bx bxs-envelope mr-1"></i>{{ detail?.user?.email }} </span>
            </p>
          </div>
          <div class="col-lg-3 col-md-4 col-12">
            <p class="mb-1 text-secondary">
              <i class="bx bxs-map"></i> {{ $t("label.Location") }}
            </p>
            <p class="font-medium">
              {{ detail?.shipping?.address }}
            </p>
          </div>

          <div class="col-lg-2 col-md-4 col-12">
            <p class="mb-1 text-secondary">{{ $t("label.Date & Time") }}</p>
            <p class="font-medium mb-1">
              <i class="bx bxs-calendar text-secondary-2"></i> {{ detail.date }}
            </p>
            <p class="font-medium">
              <i class="bx bxs-time text-secondary-2"></i> {{ detail.time }}
            </p>
          </div>
          <div class="col-lg-2 col-md-4 col-12">
            <p class="mb-1 text-secondary">{{ $t("label.Preferred Date & Time") }}</p>
            <p class="font-medium mb-1">
              <i class="bx bxs-calendar text-secondary-2"></i>
              {{ detail.preferred_date }}
            </p>
            <p class="font-medium" v-if="detail.preferred_time">
              <i class="bx bxs-time text-secondary-2"></i>
              <span v-if="detail.preferred_time == '06_AM_TO_10_AM'">{{
                $t("label.06 AM TO 10 AM")
              }}</span>
              <span v-if="detail.preferred_time == '10_AM_TO_02_PM'">{{
                $t("label.10 AM TO 02 PM")
              }}</span>
              <span v-if="detail.preferred_time == '02_PM_TO_06_PM'">{{
                $t("label.02 PM TO 06 PM")
              }}</span>
              <span v-if="detail.preferred_time == '06_PM_TO_10_PM'">{{
                $t("label.06 PM TO 10 PM")
              }}</span>
            </p>
          </div>

          <div class="col-lg-2 col-md-4 col-12">
            <p class="mb-2 text-secondary">{{ $t("label.Supplier Detail") }}</p>
            <p class="font-medium mb-2">
              <i class="bx bxs-user mr-1"></i>
              {{ detail?.supplier?.name }}<br />
              <i class="bx bxs-phone mr-1"></i>{{ detail?.supplier?.phone_number }}<br />
              <i class="bx bxs-map mr-1"></i>{{ detail?.supplier?.address }}
            </p>
          </div>

          <div class="col-lg-3 col-md-4 col-12" v-if="detail.transaction">
            <p class="mb-2 text-secondary">{{ $t("label.Transaction Detail") }}</p>
            <p class="mb-2">
              {{ $t("label.Transaction Amount") }}:
              <span class="font-bold font-16"
                >SAR {{ detail?.transaction?.trans_amount }}</span
              >
            </p>
            <p class="mb-2">
              {{ $t("label.Transaction Id") }}:
              <span class="font-bold font-16">{{ detail?.transaction?.trans_id }}</span>
            </p>
            <p class="mb-2">
              {{ $t("label.Transaction Date & Time") }}:
              <span class="font-bold font-16">{{ detail?.transaction?.trans_date }}</span>
            </p>
            <p class="mb-2">
              {{ $t("label.Payment Status") }}:
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_status == 'PENDING'"
                >{{ $t("label.Pending") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_status == 'SUCCESS'"
                >{{ $t("label.Success") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_status == 'FAILED'"
                >{{ $t("label.Failed") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_status == 'CANCELLED'"
                >{{ $t("label.Cancelled") }}</span
              >
            </p>
            <p class="mb-2">
              {{ $t("label.Payment Mode") }}:
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_mode == 'Cash On Delivery'"
                >{{ $t("label.Cash On Delivery") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_mode == 'PayPal'"
                >{{ $t("label.PayPal") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_mode == 'Card Payment'"
                >{{ $t("label.Card Payment") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_mode == 'Net Banking'"
                >{{ $t("label.Net Banking") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail?.transaction?.payment_mode == 'Cash '"
                >{{ $t("label.Cash ") }}</span
              >
            </p>
          </div>

          <div class="col-lg-3 col-md-4 col-12">
            <p class="mb-1 text-secondary">{{ $t("label.Order Detail") }}</p>
            <p class="mb-2">
              {{ $t("label.Order Id: #") }}
              <span class="font-bold font-16"> {{ detail?.id }}</span>
            </p>
            
            <p class="mb-2">
                {{ $t("label.Order Number") }}
                <span class="font-bold font-16"> {{ detail?.order_no }}</span>
              </p>
            <p class="mb-2">
              {{ $t("label.Order Status") }}

              <span
                class="font-bold font-16"
                v-if="detail.status == 'PENDING_AT_SUPPLIER'"
                >{{ $t("label.Pending at supplier") }}</span
              >
              <span
                class="font-bold font-16"
                v-if="detail.status == 'PENDING_AT_CUSTOMER'"
                >{{ $t("label.Pending at customer") }}</span
              >
              <span class="font-bold font-16" v-if="detail.status == 'ACCEPTED'">{{
                $t("label.Accepted")
              }}</span>
              <span class="font-bold font-16" v-if="detail.status == 'REJECTED'">{{
                $t("label.Rejected")
              }}</span>
              <span class="font-bold font-16" v-if="detail.status == 'CANCELLED'">{{
                $t("label.Cancelled ")
              }}</span>
              <span
                class="font-bold font-16"
                v-if="detail.status == 'PAYMENT_PENDING '"
                >{{ $t("label.Payment Pending ") }}</span
              >
              <span class="font-bold font-16" v-if="detail.status == 'COMPLETED '">{{
                $t("label.Completed ")
              }}</span>
            </p>
            
            <p class="mb-2">
                {{ $t("label.Additional information") }}
                <span class="font-bold font-16"> {{ detail?.shipping?.narration }}</span>
              </p>
              <p class="mb-2">
                {{ $t("label.Extra Note") }}
                <span class="font-bold font-16"> {{ detail?.extra_notes }}</span>
              </p>
          </div>
        </div>
      </div>
        <table
          class="table table-style-1 table-responsive mb-4 table table-no-responsive-md view-order"
        >
          <thead>
            <tr>
              <th scope="col">{{ $t("label.S_No") }}</th>
              <th scope="col">{{ $t("label.Image") }}</th>
              <th scope="col" class="serive-name">{{ $t("label.Product Name") }}</th>

              <th scope="col">{{ $t("label.Quantity") }}</th>
              <th scope="col" class="text-right">{{ $t("label.Amount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in detail?.products" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <img
                  :src="data?.product?.image ? data?.product?.image:'/images/placeholder-image.jpg'"
                  width="50"
                  height="40"
                  class="object-cover"
                />
              </td>
              <td>{{ data?.product.title }}</td>
              <td>{{ data.quantity }}</td>
              <td class="text-right">SAR {{ data.amount }}</td>
            </tr>
            <tr>
              <td colspan="5" class="text-right">
                <span class="mr-md-4 mr-3 font-16">{{ $t("label.Sub Total") }}</span>
                <span class="font-bold font-16">SAR {{ detail.sub_total }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right border-0">
                <span class="mr-md-4 mr-3 font-16">{{ $t("label.Discount") }}</span>
                <span class="font-bold font-16">SAR {{ detail.discount }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right border-0">
                <span class="mr-md-4 mr-3 font-16">{{
                  $t("label.Delivery Charges")
                }}</span>
                <span class="font-bold font-16">SAR {{ detail.shipping_charges }} </span>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right border-0">
                <span class="mr-md-4 mr-3 font-16"
                  >{{ $t("label.VAT") }} {{ $t("label.Include") }}</span
                >
                <span class="font-bold font-16">SAR {{ detail.taxes }} </span>
              </td>
            </tr>
            <tr>
              <td class="text-left border-0" colspan="4">
                <span class="mr-md-4 mr-3 font-16"
                  >{{ $t("label.Admin Commission") }} ({{ $t("label.Including Tax") }}) :</span
                >
                <span class="font-bold font-16">SAR {{ detail.admin_commission }} </span>
              </td>
              <td colspan="4" class="text-right border-0">
                <span class="mr-md-4 mr-3 font-16">{{ $t("label.Total") }}</span>
                <span class="font-bold font-16">SAR {{ detail.net_amount }} </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row" v-if="detail.cancel_reason">
          <div class="col-12 d-flex flex-row">
            <p class="pr-2 fw-bold text-danger" style="font-size: 16px">
              {{ $t("label.Note") }} :
            </p>
            <p class="pr-2 text-danger">{{ detail.cancel_reason }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="button"
              class="btn btn-brand-1 cancel btn-h-40"
              v-on:click="$router.go(-1)"
            >
              <i class="bx bx-chevron-left ml-n2"></i> {{ $t("label.Back") }}
            </button>
            <button
              type="button"
              v-show="detail.status == 'ACCEPTED'"
              class="btn btn-brand-1 bg-success btn-h-40 my-md-0 my-2 ml-1 mr-1"
              v-on:click="complete(detail.id)"
            >
              {{ $t("label.Mark Delivery Complete") }}
            </button>
            <button
              type="button"
              v-show="detail.status == 'PENDING_AT_SUPPLIER'"
              class="btn btn-brand-1 bg-danger btn-h-40 my-md-0 my-2 ml-1 mr-1"
              v-on:click="this.$refs.textModal.showModal()"
            >
              {{ $t("label.Reject") }}
            </button>
            <button
              type="button"
              v-show="detail.status == 'PENDING_AT_SUPPLIER'"
              class="btn btn-brand-1 bg-success btn-h-40"
              v-on:click="updateOrderStatus('ACCEPTED')"
            >
              {{ $t("label.Accept") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{ $t("label.Reason") }}</h5>
        </div>
        <Form @submit="updateOrderStatus('REJECTED')">
          <div class="modal-body">
            <div class="floating-form-group">
              <label class="font-medium">{{ $t("label.Reason") }}</label>

              <Field
                id="reason"
                name="reason"
                v-model="reason"
                v-slot="{ field }"
                class="form-control"
                rules="required:reason"
                :validateOnInput="true"
              >
                <textarea type="text-area" class="form-control" v-bind="field" />
              </Field>
            </div>
            <ErrorMessage name="reason" class="validation-msg mt-2" />
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn"
              id="save-button"
              style="width: 18%; border-radius: 5px"
            >
              {{ $t("label.Save") }}
            </button>
            <button
              type="button"
              class="fil-btn"
              id="cancel-button"
              style="width: 18%; border-radius: 5px"
              v-on:click="$refs.textModal.closeModal()"
            >
              {{ $t("label.Cancel") }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
</template>

<script>
import TextModal from "../../../components/TextModal.vue";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ProductOrderDetail",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.order_id,
      detail: {
        date: "",
        time: "",
      },
      reason: "",
    };
  },
  mounted() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      this.$api
        .getAPI({
          _action: "order/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          (this.detail.date = moment(res.info.created_at).format("dddd DD MMMM")),
            (this.detail.time = moment(res.info.created_at).format("hh:mm a"));
          // console.log("object.................",this.detail);
        })
        .catch(() => {});
    },
    complete(id) {
      this.$api
        .putAPI({
          _action: "order/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.push("/manage-product-order");
        });
    },
    updateOrderStatus(status) {
      var obj = {};
      if (status == "REJECTED") {
        obj.reason = this.reason;
      }

      obj.status = status;
      this.$api
        .putAPI({
          _action: "order/status/" + this.id,
          _body: obj,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
            this.$router.push("/manage-product-order");
          }
        })
        .catch(() => {});
    },
  },
};
</script>

