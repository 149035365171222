<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-md-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i></a
              >{{ $t("label.Edit Products") }}
            </h2>
          </div>
          <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
        </div>
        <div class="card card-body">
          <div class="row mb-md-4 mb-3">
            <div class="col-md-12 mb-4">
              <p class="font-medium">{{ $t("label.Product image") }}</p>

              <div
                class="d-flex align-items-center overflow-auto pb-2"
                v-for="(data, index) in detail.images"
                :key="index"
              >
                <img
                  :src="data.image ? data.image : '/images/placeholder-image.jpg'"
                  class="object-cover mr-3"
                  width="200"
                  height="150"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Product Name") }}</label>
                <p>{{ detail.title }}</p>
              </div>
              <div class="form-group">
                <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Product Description") }}</label>
                <p>
                  {{ detail.description }}
                </p>
              </div>
            </div>
            <div class="col-12 mt-4">
              <h4 class="font-18 font-medium">{{ $t("label.Details") }}</h4>
            </div>
            <div class="col-md-4 col-6" v-for="(data, index) in detail.attributes" :key="index">
              <label class="text-secondary-2 font-14 mb-0">{{ data.title }}</label>
              <p>{{ data.values }}</p>
            </div>
            <div class="col-12 col-6">
              <hr />
            </div>
            <div class="col-md-4">
              <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Category") }}</label>
              <p>{{ detail?.category?.title }}</p>
            </div>
            <div class="col-md-4">
              <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Sub-Category") }}</label>
              <p>{{ detail?.sub_category?.title }}</p>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Price") }}</label>
              <input
                v-if="detail.supplier_price"
                type="text"
                class="form-control"
                v-model="detail.supplier_price.price"
                style="width: 100px"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-secondary-2 font-14 d-block">{{ $t("label.Out of stock") }}</label>
              <div class="custom-control custom-switch d-inline mr-3">
                <input
                  v-if="detail.supplier_price"
                  type="checkbox"
                  class="custom-control-input"
                  id="shwitch"
                  true-value="Y"
                  false-value="N"
                  v-model="detail.supplier_price.is_out_of_stock"
                  v-on:change="
                    setAvailability(index, detail.supplier_price.is_out_of_stock)
                  "
                />
                <label class="custom-control-label" for="shwitch_1"></label>
              </div>
            </div>
            <div class="col-12 text-center">
              <button type="button" class="btn btn-brand-1 mx-2">{{ $t("label.Save") }}</button>
              <button type="button" class="btn btn-brand-1 cancel mx-2" onclick="goBack()">
                {{ $t("label.Cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditProduct",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  mounted() {
    this.getProductDetail();
  },
  methods: {
    getProductDetail() {
      this.$api
        .getAPI({
          _action: "product/" + this.id,
        })
        .then((res) => {
          if (res.info?.images.length <= 0) {
            res.info.images.push({});
          }
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      // if (!data.price) {
      //   this.$notify({
      //     type: "success",
      //     text: "Please enter price",
      //   });
      // }
      var obj = {};
      obj.product_id = this.detail.id;
      obj.price = this.detail?.supplier_price?.price;
      this.$api
        .postAPI({
          _action: "product/price/" + this.detail?.supplier_price?.product_id,
          _body: obj,
        })
        .then((res) => {
          this.getProductList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
          ``;
        })
        .catch(() => {});
    },
    setAvailability(index, id) {
      var obj = {};
      for (var i in this.serviceList) {
        if (i == index) {
          obj.has_service_available = this.serviceList[i].service.has_service_available;
        }
      }
      this.$api
        .putAPI({
          _action: "service/availability/" + id,
          _body: obj,
        })
        .then((res) => {
          this.getServiceList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
