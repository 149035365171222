<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 text-md-left">
                  <h2 class="page-title mb-0">
                  <a class="back-arrow" v-on:click="this.$router.go(-1)">
                    <i class="bx bx-arrow-back"></i>
                  </a>
                  {{ $t("label.Operating Area/Region") }}
                
                </h2>
                </div>
                <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
              </div>
              <div class="card card-body form-style content-card">
                <div class="row">
                  <div class="col-md-12">
                    <label> {{ $t("label.Operating Area/Region") }}</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="bx bxs-map"></i></span>
                      </div>
                      <vue-google-autocomplete
                        ref="address"
                        id="map"
                        :classname="detail.area ? 'form-control filled' : 'form-control'"
                        :placeholder="$t('label.Type Here')"
                        v-model="detail.area"
                        v-on:placechanged="getAddressData"
                        types="(cities)"
                      >
                      </vue-google-autocomplete>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.323766427248!2d73.77111501539648!3d18.514265674198004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a69232371%3A0x4aeacdb04064f3b8!2sPebbles%20Urbania!5e0!3m2!1sen!2sin!4v1633606586757!5m2!1sen!2sin"
                        width="100%"
                        height="400vh"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        class="mt-4"
                      ></iframe>
                      <gmap-map
                        :center="center"
                        :zoom="10"
                        class="col-lg-7"
                        style="height: 555px"
                        v-if="locations.length > 0"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(gmp, index) in locations"
                          :position="gmp"
                          @click="center = gmp"
                        ></gmap-marker>
                      </gmap-map>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-4 px-0">
                    <button type="button" class="btn btn-brand-1 mx-2">
                      {{ $t("label.Save") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-brand-1 cancel mx-2"
                      onclick="goBack();"
                    >
                      {{ $t("label.Cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "OperatingArea",
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      detail: {},
      center: {},
      distance: "",
      locations: [],
      latitude: "",
      longitude: "",
    };
  },
  computed: {
    getArea() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {
    this.$refs.address.focus();
    var $this = this;
    setTimeout(() => {
      // document.getElementById("title").value = $this.meetingData.title;
      document.getElementById("map").value = $this.detail.area;
    }, 500);
    this.detail = $this.getArea;
    //   consol e.log("object",this.detail);
  },
  methods: {
    getAddressData: function (addressData) {
      this.detail.address = addressData.locality;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    showMap() {
      if (this.detail.latitude && this.detail.longitude) {
        this.distance = this.$api.getDistance(
          this.detail.latitude,
          this.detail.longitude
        );
        var obj = {
          lat: this.detail.latitude,
          lng: this.detail.longitude,
          label: this.detail.area,
        };

        if (!this.center.lat) {
          this.center = obj;
        }
      }
      this.location.push(obj);
      // showLocation(detail) {
      //   var url =
      //     "https://www.google.com/maps?saddr=" +
      //     this.$api.getLatitude() +
      //     "," +
      //     this.$api.getLongitude() +
      //     "&daddr=" +
      //     detail.latitude +
      //     "," +
      //     detail.longitude;
      //   window.open(url);
      // },
    },
  },
};
</script>

