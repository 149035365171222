<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-3 align-items-center">
          <div class="col-md-6 text-md-left">
            <h2 class="page-title mb-0 pb-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t("label.New Order") }}
            </h2>
          </div>
          <!-- <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div> -->
        </div>

        <div class="card card-body content-card">
          <div class="card-title">
            <h4>{{ $t("label.Create New Order") }}</h4>
          </div>
          <div class="">
            <!-- <div class="col-lg-12"> -->
              <div class="col-lg-12 col-md-12 px-0">
                <div class="card mb-3">
                  <div class="card-body px-0">
                    <div class="row mx-auto">
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                          <label class="">{{ $t("label.Preferred Date") }}</label>
                          <span class="text-danger">*</span>
                          <VueDatePicker
                            id="preferred_date"
                            v-model="date"
                            :disabled-dates="notBeforeDate"
                            @update:model-value="update()"
                            :placeholder="$t('label.Please select preferred date')"
                            :enable-time-picker="false"
                          />
                        </div>
                        <ErrorMessage name="preferred_date" class="validation-msg" />
                      </div>
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                          <label class="">{{ $t("label.Preferred Time") }}</label>
                          <span class="text-danger">*</span>
                          <select
                            name=""
                            class="form-control"
                            v-model="preferred_time"
                            id=""
                          >
                            <option value="06_AM_TO_10_AM">
                              {{ $t("label.06 AM TO 10 AM") }}
                            </option>
                            <option value="10_AM_TO_02_PM">
                              {{ $t("label.10 AM TO 02 PM") }}
                            </option>
                            <option value="02_PM_TO_06_PM">
                              {{ $t("label.02 PM TO 06 PM") }}
                            </option>
                            <option value="06_PM_TO_10_PM">
                              {{ $t("label.06 PM TO 10 PM") }}
                            </option>
                          </select>
                        </div>
                        <ErrorMessage name="preferred_time" class="validation-msg" />
                      </div>
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                          <label class="">{{ $t("label.Time Needed") }}</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            id="time_needed"
                            name="time_needed"
                            v-model="time_needed"
                            class="form-control filled"
                            :validateOnInput="true"
                          />
                        </div>
                        <ErrorMessage name="time_needed" class="validation-msg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body px-0">
                    <div class="row mx-auto" v-if="showForm == '1'">
                      <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                          <label class="">{{ $t("label.Description") }}</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            id="title"
                            name="title"
                            v-model="options.description"
                            class="form-control filled"
                            :validateOnInput="true"
                          />
                        </div>
                        <ErrorMessage name="title" class="validation-msg" />
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                          <label class="">{{ $t("label.Amount") }}</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="number"
                            id="price"
                            name="price"
                            v-model="options.price"
                            class="form-control filled"
                            min="0"
                            :validateOnInput="true"
                          />
                        </div>
                        <ErrorMessage name="price" class="validation-msg" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-brand-1"
                          v-on:click="addSelfCalculation()"
                        >
                          {{ $t("label.Add") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="py-2 col-lg-12 d-flex justify-content-end"
                v-if="showForm == '0'"
              >
                <button type="button" class="btn btn-brand-1" v-on:click="toShowFrom()">
                  {{ $t("label.Add More") }}
                </button>
              </div>
              <div class="col-lg-12 col-md-12 pt-5 px-0" v-if="optionsList.length > 0">
                <div class="card">
                  <div class="card-body">
                    <div class="table">
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.S_No") }}</th>
                            <th>{{ $t("label.Description") }}</th>
                            <th>{{ $t("label.Price") }}</th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in optionsList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ data?.description }}</td>
                            <td>{{ data?.price }} SAR</td>
                            <td>
                              <div class="d-flex flex-row">
                                <button
                                  class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                  v-on:click="editOptions(data, index)"
                                  title="Edit"
                                >
                                  <i class="bx bxs-pencil"></i>
                                </button>
                                <button
                                  class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                  v-on:click="deleteOptions(data, index)"
                                  title="Delete"
                                >
                                  <i class="bx bxs-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- v-if="optionsList.length > 0" -->
              <div v-if="optionsList.length > 0">
                <div class="card mt-3 col-lg-2 mx-auto">
                
                <div class="row p-3">
                        <div class="col-lg-6 col-6 px-0"><b>{{ $t("label.SubTotal") }}:</b></div>
                        <div class="col-lg-6 col-6 px-0">{{ Subtotal }} SAR</div>
                      </div>
                      
                   </div>
                  </div>
              <div class="col-lg-12 py-2 px-0" v-if="optionsList.length > 0">
                <div class="col-lg-12 col-md-12 mx-auto px-0">
                  <div class="text-center mt-3">
                    <button
                      type="button"
                      id="submit-button"
                      class="btn btn-brand-1 mx-2"
                      v-on:click="submit()"
                    >
                      {{ $t("label.Submit") }}
                    </button>
                    <button
                      class="btn btn-brand-2 mx-2"
                      id="submit-button"
                      v-on:click="this.$router.go(-1)"
                    >
                      {{ $t("label.Cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto ">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ $t("label.Edit Order") }}</h4>
        </div>
        <div class="modal-body px-0">
          <div class="col-lg-12 col-md-12 px-0">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.Description") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        v-model="options.description"
                        class="form-control filled"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="title" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.Price") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="number"
                        id="price"
                        name="price"
                        v-model="options.price"
                        class="form-control filled"
                        min="0"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="price" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="btn-brand-1"
            id="save-button"
            v-on:click="updateSelfCalculation()"
          >
            {{ $t("label.Update") }}
          </button>
          <button
            type="button"
            class="btn-brand-2"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal()"
          >
            {{ $t("label.Cancel") }}
          </button>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal";
import VueDatePicker from "@vuepic/vue-datepicker";
// import { ref } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import $ from "jquery";
export default {
  name: "EditService",
  components: { TextModal, Field, ErrorMessage, VueDatePicker },

  data() {
    return {
      id: this.$route.params.id,
      date: "",
      time_needed: "",
      preferred_time: "",
      optionsList: [],
      showForm: "1",
      options: {},
      Subtotal: "",
    };
  },

  watch: {
    date() {
      this.update(); // Call the method when selected date changes
    },
  },

  mounted() {
    this.getOrderDetail();
  },

  methods: {
    update() {
      this.preferred_time = "";
      console.log(this.date);
      const selectedDate = new Date(this.date);
    const today = new Date();
    
    // Check if the selected date is today
    if (
      selectedDate.getDate() === today.getDate() &&
      selectedDate.getMonth() === today.getMonth() &&
      selectedDate.getFullYear() === today.getFullYear()
    ) {
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      const currentOption = this.getCurrentTimeOption(currentHour, currentMinute);
      
      // Disable past time options based on current time
      this.disableTimeOptionsBefore(currentOption);
    } else {
      // If the selected date is not today, enable all options
      this.enableAllTimeOptions();
    }
  },
  // Function to get the current time option based on the hour and minute
  getCurrentTimeOption(hour, minute) {
    if (hour < 10 || (hour === 10 && minute < 1)) {
      return '06_AM_TO_10_AM';
    } else if (hour < 14 || (hour === 14 && minute < 1)) {
      return '10_AM_TO_02_PM';
    } else if (hour < 18 || (hour === 18 && minute < 1)) {
      return '02_PM_TO_06_PM';
    } else {
      return '06_PM_TO_10_PM';
    }
  },
  // Function to disable time options before the current option
  disableTimeOptionsBefore(currentOption) {
    const options = ['06_AM_TO_10_AM', '10_AM_TO_02_PM', '02_PM_TO_06_PM', '06_PM_TO_10_PM'];
    const currentOptionIndex = options.indexOf(currentOption);
    for (let i = 0; i < currentOptionIndex; i++) {
      const optionElement = document.querySelector(`option[value="${options[i]}"]`);
      if (optionElement) {
        optionElement.disabled = true;
      }
    }
  },
  // Function to disable all time options
  enableAllTimeOptions() {
    const options = ['06_AM_TO_10_AM', '10_AM_TO_02_PM', '02_PM_TO_06_PM', '06_PM_TO_10_PM'];
    options.forEach(option => {
      const optionElement = document.querySelector(`option[value="${option}"]`);
      if (optionElement) {
        optionElement.disabled = false;
      }
    });
  },
    notBeforeDate(date) {
      var current_date = new Date();
      return date < current_date.setDate(current_date.getDate() - 1);
    },
    submit() {
      console.log("OptionLIst data ", this.optionsList);
      var date = moment(String(this.date)).format("Y-MM-DD");

      this.$api
        .postAPI({
          _action: "order/" + this.id + "/generate",
          _buttonId: "save-button",
          _body: {
            preferred_time: this.preferred_time,
            preferred_date: date,
            time_needed: this.time_needed,
            services: this.optionsList,
          },
        })
        .then((res) => {
          this.$router.go(-1);
          this.$notify({
            type: "success",
            text: res.message,
          });
        });
    },
    
    getOrderDetail() {
      this.$api
        .getAPI({
          _action: "order/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          (this.detail.date = moment(res.info.created_at).format("dddd DD MMMM")),
            (this.detail.time = moment(res.info.created_at).format("hh:mm a"));
          // console.log("object.................",this.detail);
        })
        .catch(() => {});
    },

    addSelfCalculation() {
      var time_needed = $("#time_needed").val();
      var title = $("#title").val();
      var amount = $("#price").val();
      if (this.date == "") {
        this.$notify({
          type: "error",
          text: this.$t("label.Please select preferred date"),
        });
        return;
      }
      if (time_needed == "") {
        this.$notify({
          type: "error",
          text: this.$t("label.Please enter time needed"),
        });
        return;
      }
      if (title == "") {
        this.$notify({
          type: "error",
          text: this.$t("label.Please enter description"),
        });
        return;
      }
      if (amount == "") {
        this.$notify({
          type: "error",
          text: this.$t("label.Please enter price"),
        });
        return;
      }

      this.optionsList.push({
        description: this.options.description,
        price: this.options.price,
      });

      let total = 0;

      this.optionsList.forEach((obj) => {
        total += parseFloat(obj.price);
      });

      this.Subtotal = parseFloat(total).toFixed(2);
      $("#title").val("");
      $("#price").val("");

      this.showForm = "1";
    },
    updateSelfCalculation() {
      this.$refs.textModal.closeModal();
      this.optionsList[this.index] = this.options;
      let total = 0;
      this.optionsList.forEach((obj) => {
        total += parseFloat(obj.amount).toFixed(2);
      });

      this.Subtotal = parseFloat(total).toFixed(2);
      this.$set(this.optionsList, this.index, this.optionsList[this.index]);
    },
    deleteOptions(data, index) {
      this.optionsList.splice(index, 1);
      let subTotal = this.subtractions(this.Subtotal, data.price);
      this.Subtotal = subTotal.toFixed(2);

      if (this.optionsList.length <= 0) {
        this.toShowFrom();
      }
    },
    toShowFrom() {
      this.showForm = "1";
      this.options = {};
    },
    editOptions(data, index) {
      this.index = index;
      var temp = JSON.stringify(data);
      var parse_data = JSON.parse(temp);
      this.options = {};
      this.options.description = parse_data.description;
      this.options.price = parse_data.price;

      this.$refs.textModal.showModal();
    },
    subtractions(a, b) {
      return a - b;
    },
    addition(a, b) {
      return a + b;
    },
  },
};
</script>

