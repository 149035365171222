<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t('label.Products') }}
                  </h2>
                </div>
                <div class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3">
                  <button
                    type="button"
                    class="btn btn-brand-1 btn-sm"
                    v-on:click="$router.push('/master-product')"
                  >
                    <i class="bx bx-plus"></i> {{ $t('label.Add New Product') }}
                  </button>
                </div>
              </div>
              <div
                class="row mb-3 align-items-center justify-content-between filter-options"
              >
                <div class="col-lg-3 col-md-4 d-md-flex mb-md-0 mb-2">
                  <div class="input-group page-cont-search">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      v-on:keyup.enter="getProductList(0)"
                      :placeholder="$t('label.search here')"
                      v-model="searchObj.keyword"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-md-8 text-right d-md-flex align-items-center">
                  <div class="selectParent mb-md-0 mb-2 mr-md-3">
                    <select
                      class="select-option"
                      v-model="searchObj.category_id"
                      v-on:change="getSubCategory()"
                    >
                      <option value="" disabled selected>{{ $t('label.Select') }} {{ $t('label.Category') }}</option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.title }}
                      </option>
                    </select>
                  </div>
                  <div class="selectParent mb-md-0 mb-2 mr-md-3">
                    <select
                      class="select-option"
                      v-model="searchObj.sub_category_id"
                      v-on:change="getProductList(0)"
                    >
                      <option value="" disabled selected>{{ $t('label.Select') }} {{ $t('label.Sub-Category') }}</option>
                      <option
                        v-for="(data, index) in subCategoryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.title }}
                      </option>
                    </select>
                  </div>
                  <div class="selectParent mb-md-0 mb-2">
                    <select class="select-option" 
                      v-on:change="getProductList(0)">
                      <option value="" disabled selected>{{ $t('label.Status') }}</option>
                      <option value="APPROVED">{{ $t('label.Approved') }}</option>
                      <option value="PENDING">{{ $t('label.Requested') }}</option>
                      <option value="REJECTED">{{ $t('label.Rejected') }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <table class="table table-style-1 table-responsive" id="sorting_tbl">
                <thead>
                  <tr>
                    <th class="sorting cursor-pointer">{{ $t('label.Image') }}</th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-title"
                      v-on:click="sorting('title', 'id-title')"
                    >
                      {{ $t('label.Product Name') }}
                    </th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-category"
                      v-on:click="sorting('title', 'id-category')"
                    >
                      {{ $t('label.Category') }}
                    </th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-sub-category"
                      v-on:click="sorting('id', 'id-sub-category')"
                    >
                      {{ $t('label.Sub-Category') }}
                    </th>
                    <th
                      class="sorting cursor-pointer"
                      id="id-price"
                      v-on:click="sorting('price', 'id-price')"
                    >
                      {{ $t('label.Price') }}
                    </th>
                    <th class="sorting cursor-pointer" id="id-status">
                      {{ $t('label.Status') }}
                    </th>
                    <th>{{ $t('label.Action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in productList" :key="index">
                    <td>
                      <img
                        :src="
                          data?.category?.image
                            ? data.category.image
                            : 'images/placeholder-image.jpg'
                        "
                        width="50"
                        height="40"
                        class="rounded"
                        alt=""
                      />
                    </td>
                    <td>
                      <a href="javascript: void(0);" class="text-primary">{{
                        data.title
                      }}</a>
                    </td>
                    <td>{{ data?.category.title }}</td>
                    <td>{{ data?.sub_category.title }}</td>
                    <td>{{ data.supplier_price.price }}</td>
                    <td>
                      <span class="text-success" v-if="data.status == 'APPROVED'">{{ $t('label.Approved') }}</span>
                      <span class="text-warning" v-if="data.status == 'PENDING'">{{ $t('label.Requested') }}</span>
                      <span class="text-danger" v-if="data.status == 'REJECTED'">{{ $t('label.Rejected') }}</span>
                    </td>
                    <td>
                      <button
                        class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                        v-if="data.status != 'REJECTED'"
                        v-on:click="$router.push('/edit-product/' + data.id)"
                      >
                        <i class="bx bxs-pencil"></i>
                      </button>
                      <button
                        class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                        v-on:click="showDeletePopup(data.supplier_price.id)"
                      >
                        <i class="bx bxs-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ErrorComponent @retry="getProductList(0)" ref="errorComponent" />
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5">
              <div class="page-bottom-pagination">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
// import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyProducts",
  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      productList: [],
      categoryList: [],
      subCategoryList: [],
      searchObj: {
        keyword: "",
        category_id: "",
        sub_category_id: "",
      },
      loader: true,
      offset: 0,
      itemsPerPage: "",
    };
  },
  mounted() {
    this.getProductList(0);
    this.getCategory();
  },
  methods: {
    pageChange(offset) {
      this.getProductList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getProductList(0);
    },
    getProductList(offset, order_by, sort_by) {
      this.loader = true;
      // this.$refs.errorComponent.updateLoader(true);
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      if (this.searchObj.category_id) {
        obj.category_id = this.searchObj.category_id;
      }
      if (this.searchObj.sub_category_id) {
        obj.sub_category_id = this.searchObj.sub_category_id;
      }
      if (order_by) {
        obj.order_by = order_by;
      }
      if (sort_by) {
        obj.sort_by = sort_by;
      }
      if (offset == 0) {
        this.productList = [];
      }
      obj.offset = offset;
      this.$api
        .getAPI({
          _action: "products",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          for (var k in res.list) {
            this.productList.push(res.list[k]);
          }
          this.offset = res.next_offset;
          this.offset = res.next_offset;
          if (this.offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.No product available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete Event"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "product/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getProductList(0);
        })
        .catch(() => {});
    },
    getCategory() {
      this.$api
        .getAPI({
          _action: "categories/product",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },
    getSubCategory() {
      this.searchObj.sub_category_id = "";
      if (this.searchObj.category_id) {
        this.$api
          .getAPI({
            _action: "categories/product/" + this.searchObj.category_id,
          })
          .then((res) => {
            this.subCategoryList = res.list;
            this.getProductList(0);
          })
          .catch(() => {});
      }
    },
    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getProductList(0, obj.order_by, obj.sort_by);
    },
  },
};
</script>

