<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left">
                <h2 class="page-title mb-md-0">Manage Services</h2>
            </div>
        </div>
          <div
            class="row justify-content-center align-items-center"
            style="min-height: 40vh"
          >
            <div class="col-lg-3 col-md-4 col-12 mb-0 mb-3">
              <div
                class="card p-4 shadow-sm text-center border-0 card-hover" style="cursor: pointer;"
                v-on:click="$router.push('/master-service')"
              >
                <img
                  src="/images/gardening-cat-service.svg"
                  width="92"
                  alt=""
                  class="mx-auto mb-3"
                />
                <p class="font-18 mb-2">{{ $t("label.Gardening Catelog Services") }}</p>
                <h2 class="text-brand mb-0">{{ detail.master_service_counts }}</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-12">
              <div
                class="card p-4 shadow-sm text-center border-0 card-hover" style="cursor: pointer;"
                v-on:click="$router.push('/my-service')"
              >
                <img
                  src="/images/all-services.svg"
                  width="92"
                  alt=""
                  class="mx-auto mb-3"
                />
                <p class="font-18 mb-2">{{ $t("label.My Services") }}</p>
                <h2 class="text-brand mb-0">{{ detail.my_service_counts }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ManageService",
  components: {
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getServices();
  },
  methods: {
    getServices() {
      this.$api
        .getAPI({
          _action: "dashboard/service",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

