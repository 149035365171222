import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import SignUp from "@/views/Onboarding/Signup/signup.vue";
import Otp from "@/views/Onboarding/Signup/otp.vue";
import Home from "@/views/Home/home.vue";
import MyProfile from "@/views/MyProfile/MyProfile.vue";
import MainView from "@/views/main.vue";
import AccountSettings from "@/views/AccountSettings/AccountSettings.vue";
import OperatingArea from "@/views/MyProfile/operating-area.vue";
import CompanyProfile from "@/views/CompanyProfile/company-profile.vue";
import ManageProducts from "@/views/ManageProducts/manage-products.vue";
import ManageService from "@/views/ManageService/manage-service.vue";
import MyProduct from "@/views/ManageProducts/my-products.vue";
import MyService from "@/views/ManageService/my-service.vue";
import EditProduct from "@/views/ManageProducts/edit-product.vue";
import MasterProduct from "@/views/ManageProducts/product-catelog.vue";
import AddProduct from "@/views/ManageProducts/add-product.vue";
import MasterService from "@/views/ManageService/service-catelog.vue";
import ManageProductOrder from "@/views/orders/product/product-order.vue";
import TermsAndConditions from "@/views/TermsAndConditions/terms.vue";
import DeliveryCharges from "@/views/ProductDeliveryCharges/product-delivery-charges.vue";
import ManageServiceOrder from "@/views/orders/service/service-order.vue";
import EarningsPage from "@/views/Earnings/earnings.vue";
import ProductOrderDetail from "@/views/orders/product/product-detail.vue";
import ServiceOrderDetail from "@/views/orders/service/service-detail.vue";
import AllWithdrawaL from "@/views/Earnings/all-withdrawal.vue";
import RequestForWithdrawal from "@/views/Earnings/requestWithdrawal.vue";
import EditService from "@/views/ManageService/edit-service.vue";
import WithdrawaLRequest from "@/views/Earnings/request-view.vue";
import NotificationInfo from "@/views/Home/notification.vue";
import AddSubOrder from "@/views/orders/service/addSubOrder.vue";
import Avaliable from "@/views/Earnings/avaliable.vue";
import PayAdmin from "@/views/Earnings/admin.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/otp",
    name: "OTP",
    component: Otp,
  },

  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/my-profile",
        name: "MyProfile",
        component: MyProfile,
      },
      {
        path: "/area",
        name: "Operating Area",
        component: OperatingArea,
      },
      {
        path: "/company-profile/:id",
        name: "Company Profile",
        component: CompanyProfile,
      },
      {
        path: "/manage-products/:id",
        name: "Manage Products",
        component: ManageProducts,
      },
      {
        path: "/manage-services/:id",
        name: "Manage Service",
        component: ManageService,
      },
      {
        path: "/my-service",
        name: "My Service",
        component: MyService,
      },
      {
        path: "/my-product",
        name: "My Product",
        component: MyProduct,
      },
      {
        path: "/settings",
        name: "AccountSettings",
        component: AccountSettings,
      },
      {
        path: "/edit-product/:id",
        name: "Edit Product",
        component: EditProduct,
      },
      {
        path: "/edit-service/:id",
        name: "Edit Service",
        component: EditService,
      },
      {
        path: "/master-product",
        name: "Master Product",
        component: MasterProduct,
      },
      {
        path: "/add-product",
        name: "Add Product",
        component: AddProduct,
      },
      {
        path: "/master-service",
        name: "Master Service",
        component: MasterService,
      },
      {
        path: "/manage-product-order",
        name: "Manage Product Order",
        component: ManageProductOrder,
      },
      {
        path: "/terms-conditions/:type",
        name: "Terms And Conditions",
        component: TermsAndConditions,
      },
      {
        path: "/delivery-charges",
        name: "Delivery Charges",
        component: DeliveryCharges,
      },
      {
        path: "/manage-service-order",
        name: "Manage Service Order",
        component: ManageServiceOrder,
      },
      {
        path: "/earnings",
        name: "EarningsPage",
        component: EarningsPage,
      },
      {
        path: "/product-order-detail/:order_id",
        name: "Product Order Detail",
        component: ProductOrderDetail,
      },
      {
        path: "/service-order-detail/:order_id",
        name: "Service Order Detail",
        component: ServiceOrderDetail,
      },
      {
        path: "/available-balance",
        name: "Available Balance",
        component: Avaliable,
      },
      {
        path: "/pay-to-admin",
        name: "Pay to Admin",
        component: PayAdmin,
      },
      {
        path: "/all-withdrawal",
        name: "All Withdrawal",
        component: AllWithdrawaL,
      },
      {
        path: "/request-for-withdrawal",
        name: "Requiest For Withdrawal",
        component: RequestForWithdrawal,
      },
      {
        path: "/withdrawal-request/:id",
        name: "WithdrawalRequest",
        component: WithdrawaLRequest,
      },
      {
        path: "/notifications",
        name: "Notification Info",
        component: NotificationInfo
      },
      {
        path: "/add-suborder/:id",
        name: "Sub Order",
        component: AddSubOrder,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (localStorage.getItem("access_token") == null) {
//       next({
//         path: "/login",
//         params: { nextUrl: to.fullPath },
//       });
//     // } else {
//     //     next({
//     //         path: "/home",
//     //         params: { nextUrl: to.fullPath },
//     //       });
//     // }
//   } else {
//     if (to.path === "/") {
//       if (localStorage.getItem("access_token") != null) {
//         next({
//           path: "/home",
//           params: { nextUrl: to.fullPath },
//         });
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   }
// });

export default router;
